import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Seo from "../../components/layout/seo";
import NostreLame from "../../components/pages/nostre-lame";

const data = {
  intro: (
    <p>
      Using the best technology and finest materials, we are able to manufacture
      sawblades with the following characteristics.
      <br />
      Precision laser cut plates with minimum tolerances.
      <br />
      <br />
      International Saws’s blades are laser cut in order to maximize the
      precision of the blade’s body itself.
      {/* <br />
        Il laser consente inoltre di ottenere lame di spessore più sottile, cosa
        impossibile con altri metodi di produzione (ad es. stampaggio)
        <br />
        Con il laser vengono eseguiti anche gli spacchi di espansione, progettati
        per permettere alla lama di dilatarsi senza subire deformazioni dannose
        alla qualità del taglio
        <br />
        La forma ed il posizionamento degli spacchi sono studiati per contenere la
        rumorosità dell’utensile dovuta alle turbolenze d’aria create dalla
        rotazione dello stesso. */}
    </p>
  ),
  blocks: [
    {
      type: "comparison",
      title: "Sawblade body",
      text: (
        <p>
          International Saws’s blades are laser cut in order to maximize the
          precision of the blade’s body itself.
          <br />
          <br />
          Our laser cutting technique allows us to cut very thin sawblades,
          impossible to obtain with other methods (i.e. die pressing, etc.).
          <br />
          <br />
          The blade’s expansion slots are also laser cut. Under particularly
          tough conditions, the centrifugal forces and heat created by friction
          between the work piece and the blade’s body make other blades dilate
          and ruin the quality of the cut. International Saws’s specifically
          engineered expansion slots solve this problem.
          <br />
          <br />
          The shape and position of the expansion slots are designed to contain
          noise pollution levels caused by air turbulence created during the
          rotation of the blade’s.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/las-is.jpg"
          layout="fullWidth"
          alt="International Saws’s blade bodies are exclusively cut by laser."
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/las-comp.jpg"
          layout="fullWidth"
          alt="With other methods of production different to that of laser technology (i. e. pressing), it is possible to produce such a thin sawblade."
        />
      ),
    },
    {
      type: "comparison",
      title: "Balancing",
      text: (
        <p>
          The International Saws blade, unlike lesser quality blades, is
          individually balanced with fully automated precision equipment to
          totally eliminate the possibility of harmful vibrations.
          <br />
          <br />A non balanced blade has different weights in various parts of
          it’s body. This provokes vibration during rotation.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/eq-is.jpg"
          layout="fullWidth"
          alt="international saws offer individual balancing"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/eq-comp.jpg"
          layout="fullWidth"
          alt="unbalanced blades"
        />
      ),
    },
    {
      type: "comparison",
      title: "Tuning",
      text: (
        <p>
          Tuning parameters are studied based on the blades sizes and types of
          application
          <br />
          <br />
          The tuning ring can be seen on most International Saws blades as a
          faint ring line about 2/3 the diameter of the blade and renders the
          sawblade more rigid during rotation.
          <br />
          <br />A sawblade without tuning ring is not stable and therefore
          vibrates producing splintering on the work piece and on the tips.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/tens-is.jpg"
          layout="fullWidth"
          alt="international saws offers blades with tension ring"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/tens-comp.jpg"
          layout="fullWidth"
          alt="blades without tension ring"
        />
      ),
    },
  ],
};

const OurBlades = ({ location }) => (
  <>
    <Seo title="Discover our blades" desc="" />
    <NostreLame data={data} location={location} />
  </>
);

export default OurBlades;
